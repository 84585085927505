import React, { useEffect } from 'react';
import {
    useReactiveVar,
    useLazyQuery,
} from '@apollo/client';
import { GET_CUSTOMER_CART_WITH_ID_ONLY } from 'graphql/cart/cart';
import { ICustomerOrder, ICustomerOrdersInput } from 'graphql/customer/customerOrdersInterfaces';
import { GET_CUSTOMER_ORDERS } from 'graphql/customer/customerOrders';
import { GET_CUSTOMER_ORDERS_INVOICES } from 'graphql/customer/customerOrdersInvoices';
import { GET_CUSTOMER_ORDERS_ITEMS_ELIGIBLE_FOR_RETURN } from 'graphql/customer/customerOrdersItemsEligibleForReturn';
import { GET_CUSTOMER_ORDERS_SHIPMENTS } from 'graphql/customer/customerOrdersShipments';
import { GET_CUSTOMER_ORDERS_REFUNDS } from 'graphql/customer/customerOrdersRefunds';
import {
    transformCustomerOrders,
    updateOrderBundles,
} from 'graphql/customer/util/transform-customer-orders';
import { useAccountLayoutLoader } from './account-layout-loader';
import {
    orderIdVar,
    currentOrderVar,
    updateCartId,
    updateCurrentOrder,
} from '../sales-layout/sales-layout-state';

interface IInitializeAccountLayoutProps {
    loadCart?: boolean;
    loadOrder?: boolean;
    splitOrderItems?: boolean;
    loadInvoices?: boolean;
    loadShipments?: boolean;
    loadRefunds?: boolean;
    loadItemsEligibleForReturn?: boolean;
    children: React.ReactNode;
}

export const InitializeAccountLayout: React.FC<IInitializeAccountLayoutProps> = (
    {
        loadCart = true,
        loadOrder = true,
        splitOrderItems = true,
        loadInvoices = false,
        loadShipments = false,
        loadRefunds = false,
        loadItemsEligibleForReturn = false,
        children,
    },
) => {
    const { accountLayoutLoading, setAccountLayoutLoading } = useAccountLayoutLoader();

    const orderId = useReactiveVar(orderIdVar);
    const currentOrder = useReactiveVar(currentOrderVar);

    const [getCustomerOrder] = useLazyQuery(GET_CUSTOMER_ORDERS, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateCurrentOrder();
        },
        onCompleted: (data): void => {
            const { customer } = data;
            const { orders } = customer;
            const { total_count: pageTotalCount } = orders;
            const totalCount: number = pageTotalCount ?? 0;
            if (totalCount < 1) {
                updateCurrentOrder();
                return;
            }

            const processedOrders = transformCustomerOrders(orders, splitOrderItems);
            const { items } = processedOrders;
            const order = items.pop();

            updateCurrentOrder(order);
        },
    });

    const [getCustomerOrderInvoices] = useLazyQuery(GET_CUSTOMER_ORDERS_INVOICES, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onCompleted: (data): void => {
            const { customer } = data;
            const { orders } = customer;
            const { total_count: pageTotalCount } = orders;
            const totalCount: number = pageTotalCount ?? 0;
            if (totalCount < 1 || !currentOrder) {
                return;
            }

            const { items } = orders;
            const order = items.pop();
            if (!order) {
                return;
            }

            const { invoices } = order;
            const updatedOrderWithInvoices: ICustomerOrder = {
                ...currentOrder,
                invoices,
            };
            const updatedOrderWithBundles: ICustomerOrder = updateOrderBundles(
                updatedOrderWithInvoices,
                'invoices',
            );

            updateCurrentOrder(updatedOrderWithBundles);
        },
    });

    const [getCustomerOrderShipments] = useLazyQuery(GET_CUSTOMER_ORDERS_SHIPMENTS, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onCompleted: (data): void => {
            const { customer } = data;
            const { orders } = customer;
            const { total_count: pageTotalCount } = orders;
            const totalCount: number = pageTotalCount ?? 0;
            if (totalCount < 1 || !currentOrder) {
                return;
            }

            const { items } = orders;
            const order = items.pop();
            if (!order) {
                return;
            }

            const { shipments } = order;
            const updatedOrderWithShipments: ICustomerOrder = {
                ...currentOrder,
                shipments,
            };
            const updatedOrderWithBundles: ICustomerOrder = updateOrderBundles(
                updatedOrderWithShipments,
                'shipments',
            );

            updateCurrentOrder(updatedOrderWithBundles);
        },
    });

    const [getCustomerOrderRefunds] = useLazyQuery(GET_CUSTOMER_ORDERS_REFUNDS, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onCompleted: (data): void => {
            const { customer } = data;
            const { orders } = customer;
            const { total_count: pageTotalCount } = orders;
            const totalCount: number = pageTotalCount ?? 0;
            if (totalCount < 1 || !currentOrder) {
                return;
            }

            const { items } = orders;
            const order = items.pop();
            if (!order) {
                return;
            }

            // eslint-disable-next-line camelcase
            const { credit_memos } = order;
            const updatedOrderWithRefunds: ICustomerOrder = {
                ...currentOrder,
                credit_memos,
            };
            const updatedOrderWithBundles: ICustomerOrder = updateOrderBundles(
                updatedOrderWithRefunds,
                'credit_memos',
            );

            updateCurrentOrder(updatedOrderWithBundles);
        },
    });

    const [getCustomerOrderItemsEligibleForReturn] = useLazyQuery(GET_CUSTOMER_ORDERS_ITEMS_ELIGIBLE_FOR_RETURN, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onCompleted: (data): void => {
            const { customer } = data;
            const { orders } = customer;
            const { total_count: pageTotalCount } = orders;
            const totalCount: number = pageTotalCount ?? 0;
            if (totalCount < 1 || !currentOrder) {
                return;
            }

            const { items } = orders;
            const order = items.pop();
            if (!order) {
                return;
            }

            // eslint-disable-next-line camelcase
            const { items_eligible_for_return } = order;
            const updatedOrderWithRefunds: ICustomerOrder = {
                ...currentOrder,
                items_eligible_for_return,
            };
            const updatedOrderWithBundles: ICustomerOrder = updateOrderBundles(
                updatedOrderWithRefunds,
                'items_eligible_for_return',
            );

            updateCurrentOrder(updatedOrderWithBundles);
        },
    });

    const [getCustomerCart] = useLazyQuery(GET_CUSTOMER_CART_WITH_ID_ONLY, {
        fetchPolicy: 'no-cache',
        onError: () => {
            updateCartId('');
        },
        onCompleted: (data): void => {
            const customerCartId = data?.customerCart?.id ?? '';
            updateCartId(customerCartId);
        },
    });

    const loadCustomerOrderData = async (customerOrdersVars: { variables: ICustomerOrdersInput }) => {
        if (loadInvoices) {
            await getCustomerOrderInvoices(customerOrdersVars);
        }

        if (loadShipments) {
            await getCustomerOrderShipments(customerOrdersVars);
        }

        if (loadRefunds) {
            await getCustomerOrderRefunds(customerOrdersVars);
        }

        if (loadItemsEligibleForReturn) {
            await getCustomerOrderItemsEligibleForReturn(customerOrdersVars);
        }
    };

    const loadAccountLayoutData = async () => {
        if (loadCart) {
            await getCustomerCart();
        }

        if (loadOrder) {
            const customerOrdersVars = {
                variables: {
                    pageSize: 1,
                    currentPage: 1,
                    filter: {
                        entity_id: {
                            eq: orderId,
                        },
                    },
                },
            };
            await getCustomerOrder(customerOrdersVars).then(async () => {
                await loadCustomerOrderData(customerOrdersVars);
            });
        }
    };

    useEffect(() => {
        if (!accountLayoutLoading) {
            setAccountLayoutLoading(true);
        }

        loadAccountLayoutData().then(() => {
            setAccountLayoutLoading(false);
        });
    }, [orderId]);

    return (<>{children}</>);
};
