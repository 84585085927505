import React, { useEffect } from 'react';
import {
    makeVar,
    ReactiveVar,
    useMutation,
    useLazyQuery,
    useReactiveVar,
} from '@apollo/client';
import { useContainer } from '@silkpwa/redux';
import { IAccountRepository } from '@silkpwa/magento/api/account-repository';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { CREATE_EMPTY_CART } from 'graphql/cart/mutations/createEmptyCart';
import {
    ISalesGuestValidOrderResult,
    SALES_GUEST_LOAD_VALID_ORDER,
} from 'graphql/guest/salesGuestLoadValidOrder';
import { GET_AUTHORIZED_GUEST_ORDER } from 'graphql/guest/authorizedGuestOrder';
import { GET_AUTHORIZED_GUEST_ORDER_INVOICES } from 'graphql/guest/authorizedGuestOrderInvoices';
import {
    GET_AUTHORIZED_GUEST_ORDER_ITEMS_ELIGIBLE_FOR_RETURN,
} from 'graphql/guest/authorizedGuestOrderItemsEligibleForReturn';
import { GET_AUTHORIZED_GUEST_ORDER_SHIPMENTS } from 'graphql/guest/authorizedGuestOrderShipments';
import { GET_AUTHORIZED_GUEST_ORDER_REFUNDS } from 'graphql/guest/authorizedGuestOrderRefunds';
import {
    transformOrder,
    updateOrderBundles,
} from 'graphql/customer/util/transform-customer-orders';
import {
    currentOrderVar,
    updateCartId,
    updateCurrentOrder,
} from '../sales-layout/sales-layout-state';
import { useGuestLayoutLoader } from './guest-layout-loader';

interface IInitializeGuestLayoutProps {
    loadCart?: boolean;
    loadOrder?: boolean;
    splitOrderItems?: boolean;
    loadInvoices?: boolean;
    loadShipments?: boolean;
    loadRefunds?: boolean;
    loadItemsEligibleForReturn?: boolean;
    children: React.ReactNode;
}

export const salesGuestValidOrderResultVar: ReactiveVar<ISalesGuestValidOrderResult|undefined> =
    makeVar<ISalesGuestValidOrderResult|undefined>(undefined);

export const updateSalesGuestValidOrderResult = (result?: ISalesGuestValidOrderResult): void => {
    salesGuestValidOrderResultVar(result);
};

export const InitializeGuestLayout: React.FC<IInitializeGuestLayoutProps> = (
    {
        loadCart = true,
        loadOrder = true,
        splitOrderItems = true,
        loadInvoices = false,
        loadShipments = false,
        loadRefunds = false,
        loadItemsEligibleForReturn = false,
        children,
    },
) => {
    const { guestLayoutLoading, setGuestLayoutLoading } = useGuestLayoutLoader();

    const accountRepository: IAccountRepository = useContainer<IAccountRepository>('accountRepository');

    const currentOrder = useReactiveVar(currentOrderVar);

    const [createEmptyCart] = useMutation(CREATE_EMPTY_CART, {
        fetchPolicy: 'no-cache',
        onError: () => {
            updateCartId('');
        },
        onCompleted: (data): void => {
            const { createEmptyCart: cartId } = data;
            if (cartId) {
                updateCartId(cartId);
            }
        },
    });

    const [salesGuestLoadValidOrder] = useLazyQuery(SALES_GUEST_LOAD_VALID_ORDER, {
        fetchPolicy: 'no-cache',
        onError: () => {
            updateSalesGuestValidOrderResult();
        },
        onCompleted: (data): void => {
            const { salesGuestLoadValidOrder } = data;
            updateSalesGuestValidOrderResult(salesGuestLoadValidOrder);
        },
    });

    const [getAuthorizedGuestOrder] = useLazyQuery(GET_AUTHORIZED_GUEST_ORDER, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateCurrentOrder();
        },
        onCompleted: (data): void => {
            const { authorizedGuestOrder } = data;

            const processedOrder = transformOrder(authorizedGuestOrder, splitOrderItems);

            updateCurrentOrder(processedOrder);
        },
    });

    const [getAuthorizedGuestOrderInvoices] = useLazyQuery(GET_AUTHORIZED_GUEST_ORDER_INVOICES, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onCompleted: (data): void => {
            const { authorizedGuestOrder } = data;
            if (!authorizedGuestOrder || !currentOrder) {
                return;
            }

            const { invoices } = authorizedGuestOrder;
            const updatedOrderWithInvoices: ICustomerOrder = {
                ...currentOrder,
                invoices,
            };

            const updatedOrderWithBundles: ICustomerOrder = updateOrderBundles(
                updatedOrderWithInvoices,
                'invoices',
            );

            updateCurrentOrder(updatedOrderWithBundles);
        },
    });

    const [getAuthorizedGuestOrderShipments] = useLazyQuery(GET_AUTHORIZED_GUEST_ORDER_SHIPMENTS, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onCompleted: (data): void => {
            const { authorizedGuestOrder } = data;
            if (!authorizedGuestOrder || !currentOrder) {
                return;
            }

            const { shipments } = authorizedGuestOrder;
            const updatedOrderWithShipments: ICustomerOrder = {
                ...currentOrder,
                shipments,
            };
            const updatedOrderWithBundles: ICustomerOrder = updateOrderBundles(
                updatedOrderWithShipments,
                'shipments',
            );

            updateCurrentOrder(updatedOrderWithBundles);
        },
    });

    const [getAuthorizedGuestOrderRefunds] = useLazyQuery(GET_AUTHORIZED_GUEST_ORDER_REFUNDS, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onCompleted: (data): void => {
            const { authorizedGuestOrder } = data;
            if (!authorizedGuestOrder || !currentOrder) {
                return;
            }

            // eslint-disable-next-line camelcase
            const { credit_memos } = authorizedGuestOrder;
            const updatedOrderWithRefunds: ICustomerOrder = {
                ...currentOrder,
                credit_memos,
            };
            const updatedOrderWithBundles: ICustomerOrder = updateOrderBundles(
                updatedOrderWithRefunds,
                'credit_memos',
            );

            updateCurrentOrder(updatedOrderWithBundles);
        },
    });

    const [getAuthorizedOrderItemsEligibleForReturn] = useLazyQuery(
        GET_AUTHORIZED_GUEST_ORDER_ITEMS_ELIGIBLE_FOR_RETURN,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
            onCompleted: (data): void => {
                const { authorizedGuestOrder } = data;
                if (!authorizedGuestOrder || !currentOrder) {
                    return;
                }

                // eslint-disable-next-line camelcase
                const { items_eligible_for_return } = authorizedGuestOrder;
                const updatedOrderWithRefunds: ICustomerOrder = {
                    ...currentOrder,
                    items_eligible_for_return,
                };
                const updatedOrderWithBundles: ICustomerOrder = updateOrderBundles(
                    updatedOrderWithRefunds,
                    'items_eligible_for_return',
                );

                updateCurrentOrder(updatedOrderWithBundles);
            },
        },
    );

    const loadGuestOrderData = async () => {
        if (loadInvoices) {
            await getAuthorizedGuestOrderInvoices();
        }

        if (loadShipments) {
            await getAuthorizedGuestOrderShipments();
        }

        if (loadRefunds) {
            await getAuthorizedGuestOrderRefunds();
        }

        if (loadItemsEligibleForReturn) {
            await getAuthorizedOrderItemsEligibleForReturn();
        }
    };

    const loadGuestLayoutData = async () => {
        await salesGuestLoadValidOrder();

        if (loadCart) {
            const currentSessionData = await accountRepository.getCustomerSession();
            if (currentSessionData?.cart_id) {
                updateCartId(currentSessionData?.cart_id);
            } else {
                await createEmptyCart();
            }
        }

        if (loadOrder) {
            await getAuthorizedGuestOrder().then(async () => {
                await loadGuestOrderData();
            });
        }
    };

    useEffect(() => {
        if (!guestLayoutLoading) {
            setGuestLayoutLoading(true);
        }

        loadGuestLayoutData().finally(() => {
            setGuestLayoutLoading(false);
        });
    }, []);

    return (<>{children}</>);
};
