import React, { useState } from 'react';
import { LoadingImage } from 'ui/component/loading-image';
import { InitializeAccountLayout } from './account-layout-state';
import { LoaderContext } from './account-layout-loader';
import { LeftSidebar } from './left-sidebar';
import { LayoutContent } from '../components/layout-content';
import { IPageBreadcrumb, PageBreadcrumbs } from '../components/page-breadcrumbs';
import salesLayoutStyles from '../sales-layout/style.css';

export interface IAccountLayoutProps {
    pageContent: JSX.Element;
    pageBreadcrumbs?: IPageBreadcrumb[];
    showLeftSidebar?: boolean;
    loadOrder?: boolean;
    loadCart?: boolean;
    splitOrderItems?: boolean;
    loadInvoices?: boolean;
    loadShipments?: boolean;
    loadRefunds?: boolean;
    loadItemsEligibleForReturn?: boolean;
}

export const AccountLayout: React.FC<IAccountLayoutProps> = (
    {
        pageContent,
        pageBreadcrumbs,
        showLeftSidebar = true,
        loadCart,
        loadOrder,
        splitOrderItems,
        loadInvoices,
        loadShipments,
        loadRefunds,
        loadItemsEligibleForReturn,
    },
) => {
    const [accountLayoutLoading, setAccountLayoutLoading] = useState(true);
    return (
        <LoaderContext.Provider value={{ accountLayoutLoading, setAccountLayoutLoading }}>
            <InitializeAccountLayout
                loadCart={loadCart}
                loadOrder={loadOrder}
                splitOrderItems={splitOrderItems}
                loadInvoices={loadInvoices}
                loadShipments={loadShipments}
                loadRefunds={loadRefunds}
                loadItemsEligibleForReturn={loadItemsEligibleForReturn}
            >
                {accountLayoutLoading && (<LoadingImage parentClass={salesLayoutStyles.layoutLoadingImage} />)}
                {!accountLayoutLoading && (
                    <>
                        {pageBreadcrumbs && (
                            <PageBreadcrumbs pageBreadcrumbs={pageBreadcrumbs} />
                        )}
                        {showLeftSidebar && (<LeftSidebar />)}
                        <LayoutContent fullWidth={!showLeftSidebar}>{pageContent}</LayoutContent>
                    </>
                )}
            </InitializeAccountLayout>
        </LoaderContext.Provider>
    );
};
