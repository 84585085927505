import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export interface ILayoutContentProps {
    fullWidth?: boolean;
    children?: JSX.Element;
}
export const LayoutContent: React.FC<ILayoutContentProps> = (
    {
        fullWidth = false,
        children,
    },
) => (
    <div
        className={classes(styles.layoutContent, {
            [styles.fullWidth]: fullWidth,
        })}
    >
        {children && (children)}
    </div>
);
