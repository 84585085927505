import React, { useState } from 'react';
import { LoadingImage } from 'ui/component/loading-image';
import { Links } from 'ui/component/account/links';
import { classes } from '@silkpwa/module/util/classes';
import { InitializeLeftSidebar } from './left-sidebar-state';
import { LoaderContext } from './left-sidebar-loader';
import styles from './style.css';

export const LeftSidebar: React.FC = () => {
    const [sidebarLoading, setSidebarLoading] = useState(false);
    return (
        <LoaderContext.Provider value={{ sidebarLoading, setSidebarLoading }}>
            <InitializeLeftSidebar>
                <div className={styles.leftSidebar}>
                    <div
                        className={classes(styles.leftSidebarContent, {
                            [styles.loading]: sidebarLoading,
                        })}
                    >
                        {sidebarLoading && (<LoadingImage parentClass={styles.sidebarLoading} />)}
                        {!sidebarLoading && (<Links />)}
                    </div>
                </div>
            </InitializeLeftSidebar>
        </LoaderContext.Provider>
    );
};
