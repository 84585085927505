import React, { useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useAccount } from '@silkpwa/module/account';
import { classes } from '@silkpwa/module/util/classes';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { GraphQlClient } from 'graphql/base';
import { MainColumn } from 'ui/component/main-column';
import { LoadingImage } from 'ui/component/loading-image';
import { InitializeSalesLayout } from './sales-layout-state';
import { LoaderContext } from './sales-layout-loader';
import { AccountLayout } from '../account-layout';
import { GuestLayout } from '../guest-layout';
import { NotLoggedIn } from '../components/not-logged-in';
import { IPageBreadcrumb } from '../components/page-breadcrumbs';
import styles from './style.css';

export interface ISalesLayoutProps {
    pageKey: string;
    pageTitle: string;
    pageContent: JSX.Element;
    isGuestPage: boolean;
    pageBreadcrumbs?: IPageBreadcrumb[];
    showLeftSidebar?: boolean;
    salesLayoutClassName?: string;
    loadCart?: boolean;
    loadCountries?: boolean;
    loadOrder?: boolean;
    splitOrderItems?: boolean;
    loadInvoices?: boolean;
    loadShipments?: boolean;
    loadRefunds?: boolean;
    loadItemsEligibleForReturn?: boolean;
    loadWidgets?: boolean;
}

export const SalesLayout: React.FC<ISalesLayoutProps> = (
    {
        pageKey,
        pageTitle,
        pageContent,
        isGuestPage,
        pageBreadcrumbs,
        showLeftSidebar,
        salesLayoutClassName,
        loadCart,
        loadCountries,
        loadOrder,
        splitOrderItems,
        loadInvoices,
        loadShipments,
        loadRefunds,
        loadItemsEligibleForReturn,
        loadWidgets,
    },
) => {
    const [salesLayoutLoading, setSalesLayoutLoading] = useState(true);

    const account = useAccount();
    const { isLoggedIn } = account;

    return (
        <ApolloProvider client={GraphQlClient}>
            <LoaderContext.Provider value={{ salesLayoutLoading, setSalesLayoutLoading }}>
                <InitializeSalesLayout
                    isLoggedIn={isLoggedIn}
                    loadCountries={loadCountries}
                    loadWidgets={loadWidgets}
                >
                    <MainColumn
                        className={classes(
                            styles.salesLayout,
                            (salesLayoutClassName || ''),
                            'sales-page',
                            pageKey, {
                                [styles.loading]: salesLayoutLoading,
                            },
                        )}
                    >
                        {salesLayoutLoading && (<LoadingImage parentClass={styles.layoutLoadingImage} />)}
                        {!salesLayoutLoading && (
                            <>
                                <DocumentTitle>{pageTitle}</DocumentTitle>
                                {!isGuestPage && (
                                    <>
                                        {isLoggedIn && (
                                            <AccountLayout
                                                pageContent={pageContent}
                                                pageBreadcrumbs={pageBreadcrumbs}
                                                showLeftSidebar={showLeftSidebar}
                                                loadCart={loadCart}
                                                loadOrder={loadOrder}
                                                splitOrderItems={splitOrderItems}
                                                loadInvoices={loadInvoices}
                                                loadShipments={loadShipments}
                                                loadRefunds={loadRefunds}
                                                loadItemsEligibleForReturn={loadItemsEligibleForReturn}
                                            />
                                        )}
                                        {!isLoggedIn && (
                                            <NotLoggedIn />
                                        )}
                                    </>
                                )}
                                {isGuestPage && (
                                    <GuestLayout
                                        pageContent={pageContent}
                                        loadCart={loadCart}
                                        loadOrder={loadOrder}
                                        splitOrderItems={splitOrderItems}
                                        loadInvoices={loadInvoices}
                                        loadShipments={loadShipments}
                                        loadRefunds={loadRefunds}
                                        loadItemsEligibleForReturn={loadItemsEligibleForReturn}
                                    />
                                )}
                            </>
                        )}
                    </MainColumn>
                </InitializeSalesLayout>
            </LoaderContext.Provider>
        </ApolloProvider>
    );
};
