import React, { createContext, useContext } from 'react';

interface ISidebarLoaderContext {
    sidebarLoading: boolean;
    setSidebarLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<ISidebarLoaderContext | undefined>(undefined);

export const useSidebarLoader = (): ISidebarLoaderContext => useContext(LoaderContext) as ISidebarLoaderContext;
