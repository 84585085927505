import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export interface INotLoggedInProps {
    className?: string;
}

export const NotLoggedIn: React.FC<INotLoggedInProps> = ({ className }) => {
    const t = usePhraseTranslater();

    return (
        <div className={classes(styles.goToHomePage, (className || ''))}>
            <span className={styles.notLoggedIn}>{t('You are not logged. Please login or')}</span>
            <a href="/" className={styles.goHomeLink}>
                <span>{t('Go to Home Page')}</span>
            </a>
        </div>
    );
};
