import React, { useEffect } from 'react';
import {
    makeVar,
    ReactiveVar,
    useLazyQuery,
    useReactiveVar,
} from '@apollo/client';
import {
    ISalesConfig,
    ISalesConfigOutput,
    GET_SALES_CONFIG,
} from 'graphql/sales/salesConfig';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { ICountry, GET_AVAILABLE_COUNTRIES } from 'graphql/store/country';
import { GET_RECENTLY_VIEWED_PRODUCTS } from 'graphql/products/recentlyViewed';
import { IWidgetProduct } from 'graphql/products/productsInterfaces';
import { getPrettyParamFromUrl } from 'ui/util/get-query-param-by-name';
import { useSalesLayoutLoader } from './sales-layout-loader';

interface IInitializeSalesLayoutProps {
    isLoggedIn: boolean;
    loadCountries?: boolean;
    loadWidgets?: boolean;
    children?: React.ReactNode;
}

export interface ISalesLayoutConfig {
    cartId: string;
    salesConfig: ISalesConfig;
}

const defaultSalesConfig: ISalesConfig = {
    websiteName: '',
    customerAccountUrl: '',
    addNewAddressUrl: '',
    productSupportUrl: '',
    customerOrdersUrl: '',
    guestOrderUrl: '',
    rmaConfig: {
        rmaCommentRequired: false,
        returnsUrl: '',
        rmaFormUrl: '',
    },
    googleMapsLibraryUrl: '',
};

const defaultSalesLayoutConfig: ISalesLayoutConfig = {
    cartId: '',
    salesConfig: defaultSalesConfig,
};

export const isLoggedInVar: ReactiveVar<boolean|null> = makeVar<boolean|null>(null);
export const orderIdVar: ReactiveVar<string> = makeVar<string>('');
export const invoiceIdVar: ReactiveVar<string> = makeVar<string>('');
export const shipmentIdVar: ReactiveVar<string> = makeVar<string>('');
export const refundIdVar: ReactiveVar<string> = makeVar<string>('');
export const currentOrderVar: ReactiveVar<ICustomerOrder|undefined> = makeVar<ICustomerOrder|undefined>(undefined);
export const salesLayoutConfigVar: ReactiveVar<ISalesLayoutConfig> =
    makeVar<ISalesLayoutConfig>(defaultSalesLayoutConfig);

export const countriesVar: ReactiveVar<ICountry[]> = makeVar<ICountry[]>([]);

export const recentlyViewedProductsVar: ReactiveVar<IWidgetProduct[]|undefined> =
    makeVar<IWidgetProduct[]|undefined>(undefined);

export const updateCartId = (cartId: string): void => {
    const accountLayoutConfig = {
        ...salesLayoutConfigVar(),
        cartId,
    };
    salesLayoutConfigVar(accountLayoutConfig);
};

export const updateSalesConfig = (salesConfig: ISalesConfig): void => {
    const accountLayoutConfig = {
        ...salesLayoutConfigVar(),
        salesConfig,
    };
    salesLayoutConfigVar(accountLayoutConfig);
};

const updateCountries = (countries: ICountry[]): void => {
    countriesVar(countries);
};

export const updateCurrentOrder = (currentOrder?: ICustomerOrder|undefined): void => {
    currentOrderVar(currentOrder);
};

const updateRecentlyViewedProducts = (recentlyViewedProducts?: IWidgetProduct[]|undefined): void => {
    recentlyViewedProductsVar(recentlyViewedProducts);
};

export const InitializeSalesLayout: React.FC<IInitializeSalesLayoutProps> = (
    {
        isLoggedIn,
        loadCountries = false,
        loadWidgets = false,
        children,
    },
) => {
    const { salesLayoutLoading, setSalesLayoutLoading } = useSalesLayoutLoader();

    const isCustomer = useReactiveVar(isLoggedInVar);
    isLoggedInVar(isLoggedIn);

    const orderId = getPrettyParamFromUrl('order_id');
    const invoiceId = getPrettyParamFromUrl('invoice_id');
    const shipmentId = getPrettyParamFromUrl('shipment_id');
    const refundId = getPrettyParamFromUrl('creditmemo_id');

    const [getSalesConfig] = useLazyQuery(GET_SALES_CONFIG, {
        fetchPolicy: 'no-cache',
        onError: () => {
            updateSalesConfig(defaultSalesConfig);
        },
        onCompleted: (data: ISalesConfigOutput) => {
            const { salesConfig } = data;
            if (salesConfig) {
                updateSalesConfig(salesConfig);
            }
        },
    });

    const [getCountries] = useLazyQuery(GET_AVAILABLE_COUNTRIES, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateCountries([]);
        },
        onCompleted: (data): void => {
            const { countries } = data;
            updateCountries(countries);
        },
    });

    const [getRecentlyViewed] = useLazyQuery(GET_RECENTLY_VIEWED_PRODUCTS, {
        variables: {
            currentPage: 1,
            pageSize: 10,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateRecentlyViewedProducts();
        },
        onCompleted: (data): void => {
            const { recentlyViewed } = data;
            const { items: recentlyViewedProducts } = recentlyViewed;

            updateRecentlyViewedProducts(recentlyViewedProducts);
        },
    });

    const loadSalesLayoutData = async () => {
        await getSalesConfig();
        if (loadCountries) {
            await getCountries();
        }

        if (loadWidgets) {
            await getRecentlyViewed();
        }
    };

    useEffect(() => {
        if (!salesLayoutLoading) {
            setSalesLayoutLoading(true);
        }

        loadSalesLayoutData().finally(() => {
            setSalesLayoutLoading(false);
        });
    }, []);

    useEffect(() => {
        if (isCustomer !== isLoggedIn) {
            isLoggedInVar(isLoggedIn);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (orderId !== '') {
            orderIdVar(orderId);
        }
    }, [orderId]);

    useEffect(() => {
        if (invoiceId !== '') {
            invoiceIdVar(invoiceId);
        }
    }, [invoiceId]);

    useEffect(() => {
        if (shipmentId !== '') {
            shipmentIdVar(shipmentId);
        }
    }, [shipmentId]);

    useEffect(() => {
        if (refundId !== '') {
            refundIdVar(refundId);
        }
    }, [refundId]);

    return children ? (<>{children}</>) : null;
};
