/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import {
    MONEY_FIELDS,
    ORDER_ITEM_FIELDS,
    SHIPMENT_TRACKING_FIELDS,
} from './customerOrderFields';

export const ORDER_SHIPMENT_FIELDS = gql`
    ${MONEY_FIELDS}
    ${ORDER_ITEM_FIELDS}
    ${SHIPMENT_TRACKING_FIELDS}
    fragment OrderShipmentFields on OrderShipment {
        id
        number
        rma_eligible_date
        tracking {
            ...ShipmentTrackingFields,
        }
        items {
            id
            order_item {
                ...OrderItemFields,
            }
            product_sale_price {
                ...MoneyFields,
            }
            quantity_shipped
        }
        printShipmentUrl
        dateFormatted
    }
`;


export const CUSTOMER_ORDER_SHIPMENTS_FIELDS = gql`
    ${ORDER_SHIPMENT_FIELDS}
    fragment CustomerOrderShipmentsFields on CustomerOrder {
        shipments {
            ...OrderShipmentFields,
        }
    }
`;
