import { gql, TypedDocumentNode } from '@apollo/client';
import { IRecentlyViewedOutput, IRecentlyViewedInput } from './recentlyViewedInterfaces';

export const PAGE_INFO_FIELDS = gql`
    fragment PageInfoFields on SearchResultPageInfo {
        page_size
        current_page
        total_pages
    }
`;

export const PRODUCT_PRICE_FIELDS = gql`
    fragment ProductPriceFields on ProductPrice {
        regular_price {
            value
        }
        final_price {
            value
        }
    }
`;

export const GET_RECENTLY_VIEWED_PRODUCTS: TypedDocumentNode<IRecentlyViewedOutput, IRecentlyViewedInput> = gql`
    ${PAGE_INFO_FIELDS}
    ${PRODUCT_PRICE_FIELDS}
    query GET_RECENTLY_VIEWED_PRODUCTS (
        $currentPage: Int = 1
        $pageSize: Int = 10
    ) {
        recentlyViewed(
            currentPage: $currentPage
            pageSize: $pageSize
        ) {
            items {
                uid
                name
                sku
                type_id
                productUrl
                thumbImages {
                    thumb
                    type
                    alt
                }
                price_range {
                    minimum_price {
                        ...ProductPriceFields,
                    }
                    maximum_price {
                        ...ProductPriceFields,
                    }
                }
            }
            page_info {
                ...PageInfoFields,
            }
            total_count
        }
    }
`;
