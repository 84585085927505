import { gql, TypedDocumentNode } from '@apollo/client';

export interface ISalesConfig {
    websiteName: string;
    customerAccountUrl: string;
    addNewAddressUrl: string;
    productSupportUrl: string;
    customerOrdersUrl: string;
    guestOrderUrl: string;
    rmaConfig: {
        rmaCommentRequired: boolean;
        returnsUrl: string;
        rmaFormUrl: string;
    };
    googleMapsLibraryUrl: string;
}
export interface ISalesConfigOutput {
    salesConfig: ISalesConfig;
}

export const GET_SALES_CONFIG: TypedDocumentNode<ISalesConfigOutput, {}> = gql`
    query GET_SALES_CONFIG {
        salesConfig {
            websiteName
            customerAccountUrl
            addNewAddressUrl
            productSupportUrl
            customerOrdersUrl
            guestOrderUrl
            rmaConfig {
                rmaCommentRequired
                returnsUrl
                rmaFormUrl
            }
            googleMapsLibraryUrl
        }
    }
`;
