/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import {
    MONEY_FIELDS,
    ORDER_ITEM_FIELDS,
    DISCOUNT_FIELDS,
    SHIPPING_HANDLING_FIELDS,
    TOTAL_REWARDS_FIELDS,
    REFUNDED_TO_STORE_CREDIT_FIELDS,
} from './customerOrderFields';


export const CREDIT_MEMO_TOTAL_FIELDS = gql`
    ${MONEY_FIELDS}
    ${DISCOUNT_FIELDS}
    ${SHIPPING_HANDLING_FIELDS}
    ${TOTAL_REWARDS_FIELDS}
    ${REFUNDED_TO_STORE_CREDIT_FIELDS}
    fragment CreditMemoTotalFields on CreditMemoTotal {
        subtotal {
            ...MoneyFields,
        }
        discounts {
            ...DiscountFields,
        }
        total_tax {
            ...MoneyFields,
        }
        grand_total {
            ...MoneyFields,
        }
        shipping_handling {
            ...ShippingHandlingFields,
        }
        total_rewards {
            ...TotalRewardsFields,
        }
        total_applied_giftcards {
            ...DiscountFields,
        }
        total_refunded_to_store_credit {
            ...RefundedToStoreCreditFields,
        }
    }
`;

export const CREDIT_MEMO_FIELDS = gql`
    ${MONEY_FIELDS}
    ${ORDER_ITEM_FIELDS}
    ${CREDIT_MEMO_TOTAL_FIELDS}
    fragment CreditMemoFields on CreditMemo {
        id
        number
        items {
            id
            order_item {
                ...OrderItemFields,
            }
            product_sale_price {
                ...MoneyFields,
            }
            quantity_refunded
        }
        total {
            ...CreditMemoTotalFields,
        }
        printRefundUrl
        dateFormatted
    }
`;


export const CUSTOMER_ORDER_REFUNDS_FIELDS = gql`
    ${CREDIT_MEMO_FIELDS}
    fragment CustomerOrderRefundsFields on CustomerOrder {
        credit_memos {
            ...CreditMemoFields,
        }
    }
`;
