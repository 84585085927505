import { ISingleRequestReturnItem } from '../../rma/rmaInterfaces';
import {
    ICustomerOrder,
    ICustomerOrdersPerPage,
    IOrderCreditMemo,
    IOrderCreditMemoItem,
    IOrderInvoice,
    IOrderInvoiceItem,
    IOrderItem,
    IOrderShipment,
    IOrderShipmentItem,
} from '../customerOrdersInterfaces';

const isParentItem = (orderItem: IOrderItem): boolean => (!orderItem.parent_item_id);
const isEmbOption = (orderItem: IOrderItem): boolean => (!!orderItem.epid);
const canShowItem = (orderItem: IOrderItem): boolean => {
    const { parentBundleItem } = orderItem;
    return Boolean(parentBundleItem) || (isParentItem(orderItem) && !isEmbOption(orderItem));
};
const showInvoiceItem = (
    invoiceItem: IOrderInvoiceItem,
): boolean => {
    const { order_item: invoiceOrderItem } = invoiceItem;
    return canShowItem(invoiceOrderItem);
};
const showShipmentItem = (
    shipmentItem: IOrderShipmentItem,
): boolean => {
    const { order_item: shipmentOrderItem } = shipmentItem;
    return canShowItem(shipmentOrderItem);
};
const showCreditMemoItem = (
    creditMemoItem: IOrderCreditMemoItem,
): boolean => {
    const { order_item: creditMemoOrderItem } = creditMemoItem;
    return canShowItem(creditMemoOrderItem);
};
export const showReturnItem = (
    returnItem: ISingleRequestReturnItem,
): boolean => {
    const { order_item: returnOrderItem } = returnItem;
    return canShowItem(returnOrderItem);
};

export const getParentOrderItems = (
    items: IOrderItem[],
): IOrderItem[] => items.filter((orderItem: IOrderItem) => orderItem.epid === '');

export const getEmbroideryItems = (
    parentOrderItem: IOrderItem,
    items: IOrderItem[],
): IOrderItem[] => items.filter((orderItem: IOrderItem) => orderItem.epid === parentOrderItem.eid);

export const getUpdatedOrderItemsWithEmbroideryItems = (
    parentItems: IOrderItem[],
    orderItems: IOrderItem[],
): IOrderItem[] => parentItems.map((parentOrderItem: IOrderItem) => {
    if (parentOrderItem.eid === '') {
        return parentOrderItem;
    }

    const embroideryItems: IOrderItem[] = getEmbroideryItems(parentOrderItem, orderItems);
    const newParentOrderItem: IOrderItem = {
        ...parentOrderItem,
        embroideryItems,
    };

    return newParentOrderItem;
});

export const transformOrder = (
    order: ICustomerOrder,
    splitOrderItems = true,
) => {
    const orderItems: IOrderItem[] = order.items;
    const parentItems: IOrderItem[] = getParentOrderItems(orderItems);
    const updatedOrderItems: IOrderItem[] = getUpdatedOrderItemsWithEmbroideryItems(parentItems, orderItems);
    if (splitOrderItems) {
        // TODO: split order items by return items
    }

    const updatedOrder: ICustomerOrder = {
        ...order,
        items: updatedOrderItems,
    };
    return updatedOrder;
};

const findParentBundleItem = (
    parentOrderItems: IOrderItem[],
    findById: string,
): IOrderItem|undefined => parentOrderItems.find((parentItem: IOrderItem) => {
    const { id, product_type: type } = parentItem;
    return type === 'bundle' && id === findById;
});

const findParentEmbItem = (
    parentOrderItems: IOrderItem[],
    findByEmbId: string,
    findById: string,
): IOrderItem|undefined => parentOrderItems.find((parentItem: IOrderItem) => {
    const { id, eid } = parentItem;
    return eid === findByEmbId && id === findById;
});

export const getUpdatedOrderItemWithParentBundle = (
    parentOrderItems: IOrderItem[],
    orderItem: IOrderItem,
): IOrderItem => {
    const { parent_item_id: orderItemParentId } = orderItem;
    if (!orderItemParentId) {
        return orderItem;
    }

    const foundParent: IOrderItem|undefined = findParentBundleItem(parentOrderItems, orderItemParentId);
    if (!foundParent) {
        return orderItem;
    }

    return {
        ...orderItem,
        parentBundleItem: foundParent,
    };
};

export const getUpdatedOrderItemWithEmbItems = (
    parentOrderItems: IOrderItem[],
    orderItem: IOrderItem,
): IOrderItem => {
    const { id, eid } = orderItem;
    const foundParentWithEmb: IOrderItem|undefined = findParentEmbItem(parentOrderItems, eid, id);
    if (!foundParentWithEmb) {
        return orderItem;
    }

    return foundParentWithEmb;
};

const updateAndFilterInvoiceOrderItems = (
    invoices: IOrderInvoice[],
    parentOrderItems: IOrderItem[],
): IOrderInvoice[] => invoices.map((invoice: IOrderInvoice): IOrderInvoice => {
    const { items: invoiceItems } = invoice;
    const updatedInvoiceItems: IOrderInvoiceItem[] = invoiceItems.map((
        invoiceItem: IOrderInvoiceItem,
    ): IOrderInvoiceItem => {
        const { order_item: invoiceOrderItem } = invoiceItem;
        const updatedOrderItem: IOrderItem = getUpdatedOrderItemWithParentBundle(parentOrderItems, invoiceOrderItem);
        return {
            ...invoiceItem,
            order_item: updatedOrderItem,
        };
    });
    const filteredInvoiceItems: IOrderInvoiceItem[] = updatedInvoiceItems.filter(
        (invoiceItem: IOrderInvoiceItem) => showInvoiceItem(invoiceItem),
    );
    const finalInvoiceItems: IOrderInvoiceItem[] = filteredInvoiceItems.map((
        invoiceItem: IOrderInvoiceItem,
    ): IOrderInvoiceItem => {
        const { order_item: invoiceOrderItem } = invoiceItem;
        const updatedOrderItem: IOrderItem = getUpdatedOrderItemWithEmbItems(parentOrderItems, invoiceOrderItem);
        return {
            ...invoiceItem,
            order_item: updatedOrderItem,
        };
    });
    return {
        ...invoice,
        items: finalInvoiceItems,
    };
});

const updateAndFilterShipmentOrderItems = (
    shipments: IOrderShipment[],
    parentOrderItems: IOrderItem[],
): IOrderShipment[] => shipments.map((shipment: IOrderShipment): IOrderShipment => {
    const { items: shipmentItems } = shipment;
    const updatedShipmentItems: IOrderShipmentItem[] = shipmentItems.map((
        shipmentItem: IOrderShipmentItem,
    ): IOrderShipmentItem => {
        const { order_item: shipmentOrderItem } = shipmentItem;
        const updatedOrderItem: IOrderItem = getUpdatedOrderItemWithParentBundle(parentOrderItems, shipmentOrderItem);
        return {
            ...shipmentItem,
            order_item: updatedOrderItem,
        };
    });
    const filteredShipmentItems: IOrderShipmentItem[] = updatedShipmentItems.filter(
        (shipmentItem: IOrderShipmentItem) => showShipmentItem(shipmentItem),
    );
    const finalShipmentItems: IOrderShipmentItem[] = filteredShipmentItems.map((
        shipmentItem: IOrderShipmentItem,
    ): IOrderShipmentItem => {
        const { order_item: shipmentOrderItem } = shipmentItem;
        const updatedOrderItem: IOrderItem = getUpdatedOrderItemWithEmbItems(parentOrderItems, shipmentOrderItem);
        return {
            ...shipmentItem,
            order_item: updatedOrderItem,
        };
    });
    return {
        ...shipment,
        items: finalShipmentItems,
    };
});

const updateAndFilterCreditMemoOrderItems = (
    creditMemos: IOrderCreditMemo[],
    parentOrderItems: IOrderItem[],
): IOrderCreditMemo[] => creditMemos.map((creditMemo: IOrderCreditMemo): IOrderCreditMemo => {
    const { items: creditMemoItems } = creditMemo;
    const updatedCreditMemoItems: IOrderCreditMemoItem[] = creditMemoItems.map((
        creditMemoItem: IOrderCreditMemoItem,
    ): IOrderCreditMemoItem => {
        const { order_item: creditMemoOrderItem } = creditMemoItem;
        const updatedOrderItem: IOrderItem = getUpdatedOrderItemWithParentBundle(parentOrderItems, creditMemoOrderItem);
        return {
            ...creditMemoItem,
            order_item: updatedOrderItem,
        };
    });
    const filteredCreditMemoItems: IOrderCreditMemoItem[] = updatedCreditMemoItems.filter(
        (creditMemoItem: IOrderCreditMemoItem) => showCreditMemoItem(creditMemoItem),
    );
    const finalCreditMemoItems: IOrderCreditMemoItem[] = filteredCreditMemoItems.map((
        creditMemoItem: IOrderCreditMemoItem,
    ): IOrderCreditMemoItem => {
        const { order_item: creditMemoOrderItem } = creditMemoItem;
        const updatedOrderItem: IOrderItem = getUpdatedOrderItemWithEmbItems(parentOrderItems, creditMemoOrderItem);
        return {
            ...creditMemoItem,
            order_item: updatedOrderItem,
        };
    });
    return {
        ...creditMemo,
        items: finalCreditMemoItems,
    };
});

const updateAndFilterEligibleForReturnOrderItems = (
    orderItemsEligibleForReturn: IOrderItem[],
    parentOrderItems: IOrderItem[],
): IOrderItem[] => {
    const updatedOrderItemsWithBundles: IOrderItem[] = orderItemsEligibleForReturn.map(
        (eligibleItem: IOrderItem): IOrderItem => (
            getUpdatedOrderItemWithParentBundle(parentOrderItems, eligibleItem)
        ),
    );
    const filteredOrderItems: IOrderItem[] = updatedOrderItemsWithBundles.filter(
        (orderItem: IOrderItem) => canShowItem(orderItem),
    );
    return filteredOrderItems.map(
        (orderItem: IOrderItem): IOrderItem => getUpdatedOrderItemWithEmbItems(parentOrderItems, orderItem),
    );
};

export const updateOrderBundles = (
    order: ICustomerOrder,
    useOrderItemsFromProperty?: 'invoices'|'shipments'|'credit_memos'|'items_eligible_for_return',
): ICustomerOrder => {
    if (!useOrderItemsFromProperty) {
        return order;
    }

    const {
        items: orderItems,
        items_eligible_for_return: orderItemsEligibleForReturn,
        invoices,
        shipments,
        credit_memos: creditMemos,
    } = order;
    const parentItems: IOrderItem[] = getParentOrderItems(orderItems);
    let updatedOrder: ICustomerOrder = order;

    switch (useOrderItemsFromProperty) {
        case 'invoices':
            updatedOrder = {
                ...order,
                invoices: updateAndFilterInvoiceOrderItems(
                    invoices,
                    parentItems,
                ),
            };
            break;
        case 'shipments':
            updatedOrder = {
                ...order,
                shipments: updateAndFilterShipmentOrderItems(
                    shipments,
                    parentItems,
                ),
            };
            break;
        case 'credit_memos':
            updatedOrder = {
                ...order,
                credit_memos: updateAndFilterCreditMemoOrderItems(
                    creditMemos,
                    parentItems,
                ),
            };
            break;
        case 'items_eligible_for_return':
            updatedOrder = {
                ...order,
                items_eligible_for_return: updateAndFilterEligibleForReturnOrderItems(
                    orderItemsEligibleForReturn,
                    parentItems,
                ),
            };
            break;
        default:
            break;
    }

    return updatedOrder;
};

export const transformCustomerOrders = (
    ordersPerPage: ICustomerOrdersPerPage,
    splitOrderItems = true,
) => {
    const updatedOrders: ICustomerOrder[] = ordersPerPage.items.map(
        (order: ICustomerOrder) => transformOrder(order, splitOrderItems),
    );

    const updatedOrdersPerPage: ICustomerOrdersPerPage = {
        ...ordersPerPage,
        items: updatedOrders,
    };
    return updatedOrdersPerPage;
};
