import React, { useState } from 'react';
import { LoadingImage } from 'ui/component/loading-image';
import { InitializeGuestLayout } from './guest-layout-state';
import { LoaderContext } from './guest-layout-loader';
import { LayoutContent } from '../components/layout-content';
import salesLayoutStyles from '../sales-layout/style.css';

export interface IGuestLayoutProps {
    pageContent: JSX.Element;
    loadCart?: boolean;
    loadOrder?: boolean;
    splitOrderItems?: boolean;
    loadInvoices?: boolean;
    loadShipments?: boolean;
    loadRefunds?: boolean;
    loadItemsEligibleForReturn?: boolean;
}

export const GuestLayout: React.FC<IGuestLayoutProps> = (
    {
        pageContent,
        loadCart,
        loadOrder,
        splitOrderItems,
        loadInvoices,
        loadShipments,
        loadRefunds,
        loadItemsEligibleForReturn,
    },
) => {
    const [guestLayoutLoading, setGuestLayoutLoading] = useState(true);
    return (
        <LoaderContext.Provider value={{ guestLayoutLoading, setGuestLayoutLoading }}>
            <InitializeGuestLayout
                loadCart={loadCart}
                loadOrder={loadOrder}
                splitOrderItems={splitOrderItems}
                loadInvoices={loadInvoices}
                loadShipments={loadShipments}
                loadRefunds={loadRefunds}
                loadItemsEligibleForReturn={loadItemsEligibleForReturn}
            >
                {guestLayoutLoading && (<LoadingImage parentClass={salesLayoutStyles.layoutLoadingImage} />)}
                {!guestLayoutLoading && (
                    <LayoutContent fullWidth>{pageContent}</LayoutContent>
                )}
            </InitializeGuestLayout>
        </LoaderContext.Provider>
    );
};
