import { gql, TypedDocumentNode } from '@apollo/client';

export interface ISalesGuestValidOrderResult {
    result: boolean;
    redirectUrl: string;
}

export interface IGuestValidOrderOutput {
    salesGuestLoadValidOrder: ISalesGuestValidOrderResult;
}

export const SALES_GUEST_LOAD_VALID_ORDER: TypedDocumentNode<IGuestValidOrderOutput, {}> = gql`
    query SALES_GUEST_LOAD_VALID_ORDER {
        salesGuestLoadValidOrder {
            result
            redirectUrl
        }
    }
`;
