import { gql, TypedDocumentNode } from '@apollo/client';

export interface ICreateEmptyCartInput {
    // eslint-disable-next-line camelcase
    cart_id: string;
}

export interface ICreateEmptyCartOutput {
    createEmptyCart: string;
}

export const CREATE_EMPTY_CART: TypedDocumentNode<ICreateEmptyCartOutput, ICreateEmptyCartInput> = gql`
    mutation CREATE_EMPTY_CART($input: createEmptyCartInput) {
        createEmptyCart(input: $input)
    }
`;
