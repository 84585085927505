/* eslint-disable camelcase */
import { gql } from '@apollo/client';

export const MONEY_FIELDS = gql`
    fragment MoneyFields on Money {
        value
    }
`;

export const PAGE_INFO_FIELDS = gql`
    fragment PageInfoFields on SearchResultPageInfo {
        page_size
        current_page
        total_pages
    }
`;

export const ORDER_URLS_FIELDS = gql`
    fragment OrderUrlsFields on OrderUrls {
        details_url
        print_order_url
        invoice_url
        print_all_invoices_url
        shipment_url
        print_all_shipments_url
        refunds_url
        print_all_refunds_url
        returns_url
        create_returns_url
    }
`;

export const SHIPMENT_TRACKING_FIELDS = gql`
    fragment ShipmentTrackingFields on ShipmentTracking {
        title
        carrier
        number
        trackingUrl
    }
`;

export const ORDER_ITEM_TRACKING_FIELDS = gql`
    ${SHIPMENT_TRACKING_FIELDS}
    fragment OrderItemTrackingFields on OrderItemTracking {
        shipmentUid
        tracks {
            ...ShipmentTrackingFields,
        }
    }
`;

export const ORDER_ITEM_FIELDS = gql`
    ${MONEY_FIELDS}
    ${ORDER_ITEM_TRACKING_FIELDS}
    fragment OrderItemFields on OrderItemInterface {
        id
        parent_item_id
        eid
        epid
        product_name
        product_sku
        associated_product_sku
        product_url
        product_image_url
        product_is_salable
        product_type
        status
        statusLabel
        itemTracking {
            ...OrderItemTrackingFields,
        }
        product_sale_price {
            ...MoneyFields,
        }
        selected_options {
            label
            value
        }
        ... on ConfigurableOrderItem {
            add_to_cart_options
        }
        ... on BundleOrderItem {
            add_to_cart_options
            bundle_options {
                uid
                values {
                    uid
                    product_name
                    product_sku
                    price {
                        ...MoneyFields,
                    }
                    quantity
                }
            }
        }
        eligible_for_return
        rma_available_qty
        quantity_ordered
        quantity_shipped
        quantity_refunded
        quantity_invoiced
        quantity_canceled
        quantity_returned
    }
`;

export const DISCOUNT_FIELDS = gql`
    fragment DiscountFields on Discount {
        amount {
            ...MoneyFields,
        }
        label
    }
`;

export const EARNED_REWARD_FIELDS = gql`
    fragment EarnedRewardFields on EarnedReward {
        amount {
            ...MoneyFields,
        }
        label
    }
`;

export const REFUNDED_TO_STORE_CREDIT_FIELDS = gql`
    fragment RefundedToStoreCreditFields on RefundedToStoreCredit {
        amount {
            ...MoneyFields,
        }
        label
    }
`;

export const SHIPPING_HANDLING_FIELDS = gql`
    fragment ShippingHandlingFields on ShippingHandling {
        amount_excluding_tax {
            ...MoneyFields,
        }
    }
`;

export const TOTAL_REWARDS_FIELDS = gql`
    ${DISCOUNT_FIELDS}
    ${EARNED_REWARD_FIELDS}
    fragment TotalRewardsFields on TotalRewards {
        spent {
            ...DiscountFields,
        }
        earned {
            ...EarnedRewardFields,
        }
    }
`;

export const ORDER_INVOICE_FIELDS_SIMPLIFIED = gql`
    fragment OrderInvoiceFieldsSimplified on Invoice {
        id
        number
        dateFormatted
    }
`;

export const ORDER_SHIPMENT_FIELDS_SIMPLIFIED = gql`
    fragment OrderShipmentFieldsSimplified on OrderShipment {
        id
        number
        dateFormatted
        rma_eligible_date
        items {
            id
            order_item {
                id
                parent_item_id
                product_sku
                rma_available_qty
            }
        }
    }
`;

export const RETURN_CUSTOM_ATTRIBUTE_FIELDS = gql`
    fragment ReturnCustomAttributeFields on ReturnCustomAttribute {
        uid
        label
        value
    }
`;


export const RMA_ITEM_UPLOADED_IMAGE_FIELDS = gql`
    fragment RmaItemUploadedImageFields on RmaItemUploadedImage {
        name
        type
        path
        url
        size
        error
        error_message
    }
`;

export const RMA_ITEM_REFUND_PROCESSING_FIELDS = gql`
    fragment RmaItemRefundProcessingFields on RmaItemRefundProcessing {
        isProcessed
        hasError
        message
    }
`;

export const RETURN_ITEM_FIELDS = gql`
    ${ORDER_ITEM_FIELDS}
    ${RETURN_CUSTOM_ATTRIBUTE_FIELDS}
    ${RMA_ITEM_UPLOADED_IMAGE_FIELDS}
    fragment ReturnItemFields on ReturnItem {
        uid
        order_item {
            ...OrderItemFields,
        }
        custom_attributes {
            ...ReturnCustomAttributeFields,
        }
        request_quantity
        quantity
        quantityReturned
        quantityApproved
        status
        statusLabel
        uploadedImages {
            ...RmaItemUploadedImageFields,
        }
    }
`;

export const SINGLE_RETURN_ITEM_FIELDS = gql`
    ${ORDER_ITEM_FIELDS}
    ${RETURN_CUSTOM_ATTRIBUTE_FIELDS}
    ${RMA_ITEM_UPLOADED_IMAGE_FIELDS}
    ${RMA_ITEM_REFUND_PROCESSING_FIELDS}
    fragment SingleReturnItemFields on ReturnItem {
        uid
        order_item {
            ...OrderItemFields,
        }
        custom_attributes {
            ...ReturnCustomAttributeFields,
        }
        request_quantity
        quantity
        quantityReturned
        quantityApproved
        status
        statusLabel
        uploadedImages {
            ...RmaItemUploadedImageFields,
        }
        refundProcessing {
            ...RmaItemRefundProcessingFields,
        }
    }
`;

export const RETURN_SHIPPING_ADDRESS_FIELDS = gql`
    fragment ReturnShippingAddressFields on ReturnShippingAddress {
        contact_name
        street
        city
        region {
            id
            code
            name
        }
        postcode
        country {
            id
            two_letter_abbreviation
            three_letter_abbreviation
            full_name_locale
            full_name_english
        }
        telephone
    }
`;

export const RETURN_CUSTOMER_FIELDS = gql`
    fragment ReturnCustomerFields on ReturnCustomer {
        email
        firstname
        lastname
    }
`;

export const RETURN_COMMENT_FIELDS = gql`
    fragment ReturnCommentFields on ReturnComment {
        uid
        created_at
        dateFormatted
        author_name
        text
    }
`;

export const ORDER_ADDRESS_FIELDS = gql`
    fragment OrderAddressFields on OrderAddress {
        firstname
        lastname
        middlename
        region
        region_id
        country_code
        street
        company
        postcode
        city
        telephone
    }
`;

export const RETURN_FIELDS = gql`
    ${RETURN_CUSTOMER_FIELDS}
    ${RETURN_SHIPPING_ADDRESS_FIELDS}
    ${RETURN_COMMENT_FIELDS}
    ${RETURN_ITEM_FIELDS}
    ${ORDER_ADDRESS_FIELDS}
    fragment ReturnFields on Return {
        uid
        number
        dateFormatted
        status
        statusLabel
        viewUrl
        order {
            id
            number
            order_date_formatted
            order_urls {
                details_url
                returns_url
            }
            shipping_address {
                ...OrderAddressFields,
            }
        }
        customer {
            ...ReturnCustomerFields,
        }
        shipping {
            address {
                ...ReturnShippingAddressFields,
            }
        }
        comments {
            ...ReturnCommentFields,
        }
        items {
            ...ReturnItemFields,
        }
        shippingMethod {
            code
            title
        }
    }
`;

export const SINGLE_RETURN_FIELDS = gql`
    ${ORDER_ITEM_FIELDS}
    ${SINGLE_RETURN_ITEM_FIELDS}
    ${RETURN_CUSTOMER_FIELDS}
    ${RETURN_SHIPPING_ADDRESS_FIELDS}
    ${RETURN_COMMENT_FIELDS}
    ${ORDER_ADDRESS_FIELDS}
    fragment SingleReturnFields on Return {
        uid
        number
        dateFormatted
        status
        statusLabel
        viewUrl
        order {
            id
            number
            order_date_formatted
            order_urls {
                details_url
                returns_url
            }
            items {
                ...OrderItemFields,
            }
            shipping_address {
                ...OrderAddressFields,
            }
        }
        customer {
            ...ReturnCustomerFields,
        }
        shipping {
            address {
                ...ReturnShippingAddressFields,
            }
        }
        comments {
            ...ReturnCommentFields,
        }
        items {
            ...SingleReturnItemFields,
        }
        shippingMethod {
            code
            title
        }
        shippingLabel
    }
`;

export const RETURN_FIELDS_SIMPLIFIED = gql`
    ${RETURN_CUSTOMER_FIELDS}
    fragment ReturnFieldsSimplified on Return {
        uid
        number
        dateFormatted
        statusLabel
        viewUrl
        customer {
            ...ReturnCustomerFields,
        }
    }
`;

export const RETURNS_FIELDS = gql`
    ${RETURN_FIELDS_SIMPLIFIED}
    ${PAGE_INFO_FIELDS}
    fragment ReturnsFields on Returns {
        items {
            ...ReturnFieldsSimplified,
        }
        page_info {
            ...PageInfoFields,
        }
        total_count
    }
`;

export const CREDIT_MEMO_FIELDS_SIMPLIFIED = gql`
    fragment CreditMemoFieldsSimplified on CreditMemo {
        id
        number
        dateFormatted
    }
`;

export const ORDER_PAYMENT_METHOD_FIELDS = gql`
    fragment OrderPaymentMethodFields on OrderPaymentMethod {
        name
        type
    }
`;

export const ORDER_TRANSACTION_FIELDS = gql`
    fragment OrderTransactionFields on OrderTransaction {
        label
        value
    }
`;

export const ORDER_TOTAL_FIELDS = gql`
    ${MONEY_FIELDS}
    ${DISCOUNT_FIELDS}
    ${SHIPPING_HANDLING_FIELDS}
    ${TOTAL_REWARDS_FIELDS}
    ${REFUNDED_TO_STORE_CREDIT_FIELDS}
    fragment OrderTotalFields on OrderTotal {
        subtotal {
            ...MoneyFields,
        }
        discounts {
            ...DiscountFields,
        }
        total_tax {
            ...MoneyFields,
        }
        grand_total {
            ...MoneyFields,
        }
        shipping_handling {
            ...ShippingHandlingFields,
        }
        total_rewards {
            ...TotalRewardsFields,
        }
        total_applied_giftcards {
            ...DiscountFields,
        }
        total_refunded_to_store_credit {
            ...RefundedToStoreCreditFields,
        }
    }
`;

export const CUSTOMER_ORDER_FIELDS = gql`
    ${ORDER_URLS_FIELDS}
    ${ORDER_ITEM_FIELDS}
    ${ORDER_INVOICE_FIELDS_SIMPLIFIED}
    ${ORDER_SHIPMENT_FIELDS_SIMPLIFIED}
    ${RETURNS_FIELDS}
    ${CREDIT_MEMO_FIELDS_SIMPLIFIED}
    ${ORDER_ADDRESS_FIELDS}
    ${ORDER_PAYMENT_METHOD_FIELDS}
    ${ORDER_TRANSACTION_FIELDS}
    ${ORDER_TOTAL_FIELDS}
    fragment CustomerOrderFields on CustomerOrder {
        id
        order_date_formatted
        status
        is_archived
        number
        order_urls {
            ...OrderUrlsFields,
        }
        items {
            ...OrderItemFields,
        }
        invoices {
            ...OrderInvoiceFieldsSimplified,
        }
        shipments {
            ...OrderShipmentFieldsSimplified,
        }
        returns {
            ...ReturnsFields,
        }
        credit_memos {
            ...CreditMemoFieldsSimplified,
        }
        shipping_address {
            ...OrderAddressFields,
        }
        billing_address {
            ...OrderAddressFields,
        }
        shipping_method
        payment_methods {
            ...OrderPaymentMethodFields,
        }
        transactions {
            ...OrderTransactionFields,
        }
        total {
            ...OrderTotalFields,
        }
    }
`;
