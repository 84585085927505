import React, { createContext, useContext } from 'react';

interface ISalesLayoutContext {
    salesLayoutLoading: boolean;
    setSalesLayoutLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<ISalesLayoutContext | undefined>(undefined);

export const useSalesLayoutLoader = (): ISalesLayoutContext => useContext(LoaderContext) as ISalesLayoutContext;
