import { gql, TypedDocumentNode } from '@apollo/client';
import { CUSTOMER_ORDER_SHIPMENTS_FIELDS } from '../customer/fragments';
import { ICustomerOrder } from '../customer/customerOrdersInterfaces';

export interface IAuthorizedGuestOrderOutput {
    authorizedGuestOrder: ICustomerOrder;
}

export const GET_AUTHORIZED_GUEST_ORDER_SHIPMENTS: TypedDocumentNode<IAuthorizedGuestOrderOutput, {}> = gql`
    ${CUSTOMER_ORDER_SHIPMENTS_FIELDS}
    query GET_AUTHORIZED_GUEST_ORDER_SHIPMENTS {
        authorizedGuestOrder {
            ...CustomerOrderShipmentsFields,
        }
    }
`;
