import React, { createContext, useContext } from 'react';

interface IAccountLayoutContext {
    accountLayoutLoading: boolean;
    setAccountLayoutLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<IAccountLayoutContext | undefined>(undefined);

export const useAccountLayoutLoader = (): IAccountLayoutContext => useContext(LoaderContext) as IAccountLayoutContext;
