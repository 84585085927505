import { gql, TypedDocumentNode } from '@apollo/client';

export interface ICustomerAccountLink {
    label: string;
    path: string;
    href: string;
    html: string;
    isVisible: boolean;
    isDelimiter: boolean;
    isTargetBlank: boolean;
}

export interface ICustomerAccountLinksOutput {
    customerAccountLinks: ICustomerAccountLink[];
}

export const GET_CUSTOMER_ACCOUNT_LINKS: TypedDocumentNode<ICustomerAccountLinksOutput, {}> = gql`
    query GET_CUSTOMER_ACCOUNT_LINKS {
        customerAccountLinks {
            label
            path
            href
            html
            isVisible
            isDelimiter
            isTargetBlank
        }
    }
`;
