import { gql, TypedDocumentNode } from '@apollo/client';
import { CUSTOMER_ORDER_INVOICES_FIELDS } from '../customer/fragments';
import { ICustomerOrder } from '../customer/customerOrdersInterfaces';

export interface IAuthorizedGuestOrderOutput {
    authorizedGuestOrder: ICustomerOrder;
}

export const GET_AUTHORIZED_GUEST_ORDER_INVOICES: TypedDocumentNode<IAuthorizedGuestOrderOutput, {}> = gql`
    ${CUSTOMER_ORDER_INVOICES_FIELDS}
    query GET_AUTHORIZED_GUEST_ORDER_INVOICES {
        authorizedGuestOrder {
            ...CustomerOrderInvoicesFields,
        }
    }
`;
