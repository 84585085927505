import React, { createContext, useContext } from 'react';

interface IGuestLayoutContext {
    guestLayoutLoading: boolean;
    setGuestLayoutLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<IGuestLayoutContext | undefined>(undefined);

export const useGuestLayoutLoader = (): IGuestLayoutContext => useContext(LoaderContext) as IGuestLayoutContext;
