/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import {
    MONEY_FIELDS,
    ORDER_ITEM_FIELDS,
    DISCOUNT_FIELDS,
    SHIPPING_HANDLING_FIELDS,
    TOTAL_REWARDS_FIELDS,
} from './customerOrderFields';


export const INVOICE_TOTAL_FIELDS = gql`
    ${MONEY_FIELDS}
    ${DISCOUNT_FIELDS}
    ${SHIPPING_HANDLING_FIELDS}
    ${TOTAL_REWARDS_FIELDS}
    fragment InvoiceTotalFields on InvoiceTotal {
        subtotal {
            ...MoneyFields,
        }
        discounts {
            ...DiscountFields,
        }
        total_tax {
            ...MoneyFields,
        }
        grand_total {
            ...MoneyFields,
        }
        shipping_handling {
            ...ShippingHandlingFields,
        }
        total_rewards {
            ...TotalRewardsFields,
        }
        total_applied_giftcards {
            ...DiscountFields,
        }
    }
`;

export const ORDER_INVOICE_FIELDS = gql`
    ${MONEY_FIELDS}
    ${ORDER_ITEM_FIELDS}
    ${INVOICE_TOTAL_FIELDS}
    fragment OrderInvoiceFields on Invoice {
        id
        number
        items {
            id
            order_item {
                ...OrderItemFields,
            }
            product_sale_price {
                ...MoneyFields,
            }
            quantity_invoiced
        }
        total {
            ...InvoiceTotalFields,
        }
        printInvoiceUrl
        dateFormatted
    }
`;


export const CUSTOMER_ORDER_INVOICES_FIELDS = gql`
    ${ORDER_INVOICE_FIELDS}
    fragment CustomerOrderInvoicesFields on CustomerOrder {
        invoices {
            ...OrderInvoiceFields,
        }
    }
`;
