import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ICustomerAccountLink } from 'graphql/customer/accountLinks';
import styles from '../style.css';

interface ILinkProps {
    link: ICustomerAccountLink;
}

export const AccountLink: React.FC<ILinkProps> = ({ link }) => {
    const {
        label,
        path,
        href,
        isVisible,
        isDelimiter,
        isTargetBlank,
    } = link;
    const isCurrent = (path: string): boolean => {
        const url: URL = new URL(window.location.href);
        const urlPathname = url.pathname.split('/')
            .filter(Boolean)
            .join('/');
        return urlPathname === path;
    };
    const isCurrentLink = isCurrent(path);
    const target = isTargetBlank ? '_blank' : '';

    if (!isDelimiter && (!label || (path === '' && href === ''))) {
        return null;
    }

    return (
        <li
            className={classes(styles.accountLink, {
                [styles.visible]: isVisible,
                [styles.current]: isCurrentLink,
            })}
        >
            {!isDelimiter && (
                !isCurrentLink
                    ? (<a className={styles.link} href={href} target={target}>{label}</a>)
                    : (<strong className={styles.link}>{label}</strong>)
            )}
            {isDelimiter && (<span className={styles.delimiter} />)}
        </li>
    );
};
