import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { customerAccountLinksVar } from 'ui/page/sales-pages/layouts/account-layout/left-sidebar/left-sidebar-state';
import { ICustomerAccountLink } from 'graphql/customer/accountLinks';
import { AccountLink } from './link';
import styles from './style.css';

export const Links: React.FC = () => {
    const checkIsMobile = () => window.innerWidth < 1041;
    const [isMobile, setIsMobile] = useState(checkIsMobile());
    const [isExpanded, setIsExpanded] = useState(false);

    const customerAccountLinks: ICustomerAccountLink[] = useReactiveVar(customerAccountLinksVar);
    const t = usePhraseTranslater();

    const checkResponsiveView = (): void => {
        setIsMobile(checkIsMobile());
    };

    const toggleExpanded = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        checkResponsiveView();
        window.addEventListener('resize', checkResponsiveView);
        return () => window.removeEventListener('resize', checkResponsiveView);
    }, []);

    if (!customerAccountLinks || !customerAccountLinks.length) {
        return null;
    }

    return (
        <div
            className={classes({
                [styles.accountLinksWrapper]: true,
                [styles.open]: isExpanded,
            })}
        >
            {isMobile && (
                <AccessibleButton
                    tag="button"
                    className={styles.myAccountButton}
                    action={toggleExpanded}
                    aria-expanded={isExpanded}
                    tabIndex={0}
                >
                    <span className={styles.myAccountLabel}>{t('My Account')}</span>
                </AccessibleButton>
            )}
            <ul
                className={classes({
                    [styles.accountLinksUl]: true,
                    [styles.collapsed]: isMobile && !isExpanded,
                })}
            >
                {customerAccountLinks.map(
                    (link: ICustomerAccountLink) => (<AccountLink link={link} />),
                )}
            </ul>
        </div>
    );
};
