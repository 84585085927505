/* eslint-disable camelcase */
import { gql, TypedDocumentNode } from '@apollo/client';
import { ICustomerOrdersInput, ICustomerOrdersOutPut } from './customerOrdersInterfaces';
import {
    CUSTOMER_ORDER_SHIPMENTS_FIELDS,
    PAGE_INFO_FIELDS,
} from './fragments';


export const GET_CUSTOMER_ORDERS_SHIPMENTS: TypedDocumentNode<ICustomerOrdersOutPut, ICustomerOrdersInput> = gql`
    ${CUSTOMER_ORDER_SHIPMENTS_FIELDS}
    ${PAGE_INFO_FIELDS}
    query GET_CUSTOMER_ORDERS_SHIPMENTS(
        $filter: CustomerOrdersFilterInput
        $currentPage: Int = 1
        $pageSize: Int = 20
        $sort: CustomerOrderSortInput
        $scope: ScopeTypeEnum
    ) {
        customer {
            orders(
                filter: $filter
                currentPage: $currentPage
                pageSize: $pageSize
                sort: $sort
                scope: $scope
            ) {
                items {
                    ...CustomerOrderShipmentsFields,
                }
                page_info {
                    ...PageInfoFields,
                }
                total_count
            }
        }
    }
`;
