import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { salesLayoutConfigVar, ISalesLayoutConfig } from '../../sales-layout/sales-layout-state';
import styles from './style.css';

export interface IPageBreadcrumb {
    label: string;
    url?: string;
}

export interface IPageBreadcrumbsProps {
    pageBreadcrumbs?: IPageBreadcrumb[];
}

export const PageBreadcrumbs: React.FC<IPageBreadcrumbsProps> = ({ pageBreadcrumbs }) => {
    const t = usePhraseTranslater();
    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const { salesConfig } = config;
    const { customerAccountUrl } = salesConfig;

    const breadcrumbs: IPageBreadcrumb[] = [];
    breadcrumbs.push({
        label: t('Your Account'),
        url: customerAccountUrl,
    });
    if (pageBreadcrumbs) {
        pageBreadcrumbs.forEach((breadcrumb: IPageBreadcrumb) => {
            breadcrumbs.push(breadcrumb);
        });
    }

    return (
        <div className={classes(styles.pageBreadcrumbs)}>
            {breadcrumbs.map(
                (breadcrumb: IPageBreadcrumb) => {
                    const { label, url } = breadcrumb;
                    return (
                        <div className={styles.breadcrumbWrapper}>
                            <div className={styles.breadcrumb}>
                                {url && (
                                    <a className={classes(styles.url)} href={url}>
                                        <span className={styles.label}>{label}</span>
                                    </a>
                                )}
                                {!url && (<span className={styles.label}>{label}</span>)}
                            </div>
                            <div className={styles.separator}>/</div>
                        </div>
                    );
                },
            )}
        </div>
    );
};
