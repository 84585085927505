import React, { useEffect } from 'react';
import { makeVar, ReactiveVar, useLazyQuery } from '@apollo/client';
import {
    GET_CUSTOMER_ACCOUNT_LINKS,
    ICustomerAccountLink,
    ICustomerAccountLinksOutput,
} from 'graphql/customer/accountLinks';
import { useSidebarLoader } from './left-sidebar-loader';

interface IInitializeLeftSidebarProps {
    children: React.ReactNode;
}

export const customerAccountLinksVar: ReactiveVar<ICustomerAccountLink[]> = makeVar([] as any);

export const InitializeLeftSidebar: React.FC<IInitializeLeftSidebarProps> = ({ children }) => {
    const { sidebarLoading, setSidebarLoading } = useSidebarLoader();

    const [getCustomerAccountLinks] = useLazyQuery(GET_CUSTOMER_ACCOUNT_LINKS, {
        fetchPolicy: 'no-cache',
        onError: () => {
            customerAccountLinksVar([]);
        },
        onCompleted: (data: ICustomerAccountLinksOutput) => {
            const { customerAccountLinks } = data;
            if (customerAccountLinks) {
                customerAccountLinksVar(customerAccountLinks);
            }
        },
    });

    const getLinks = async () => {
        await getCustomerAccountLinks();
    };

    useEffect(() => {
        if (!sidebarLoading) {
            setSidebarLoading(true);
        }

        getLinks().finally(() => {
            setSidebarLoading(false);
        });
    }, []);

    return (<>{children}</>);
};
